<template>
  <page-item collection="library" :limit="10" :texts="texts">
    <template #subheader>
      <div class="row">
        <div class="col-12 mb-4">
          <p>
            This library aims to centralise and list all Life Cycle Inventories
            related to the Battery value chain, available in databases or
            developed by companies.
            <i
              class="bi bi-info-square-fill ms-1 text-danger"
              @click="showModal = true"
            />
          </p>
        </div>
      </div>
    </template>
  </page-item>
  <el-dialog v-model="showModal">
    <template #header>
      <h4 class="text-primary">About Batters' Library</h4>
    </template>
    <div class="row">
      <div class="col-12">
        <p class="modal-content fs-18 break-word">
          This will allow the community of LCA experts in batteries to know if
          companies, universities, or different kinds of institutions have
          developed specific LCIs for innovative recycling processes for example
          or a new technology of battery, or else that would not be found in
          generic databases. That way, battery manufacturers subject to LCA
          declaration regulation will be able to reach out to actors who can
          help them to improve their environmental impacts with up-to-date LCIs.
          It can also be a way for the LCA community to identify common
          interests, discuss common challenges and improve our overall knowledge
          of LCA for the battery industry. If you are interested to know more
          about a particular inventory, please contact the owner of the data
          whose name is available in the library. This functionality is still
          under development and being enhanced. If you have developed specific
          LCIs related to batteries through your projects and would like to be
          referenced in this library, please contact us at
          <a href="mailto:info@weloop.org" class="inline-link"
            >info@weloop.org</a
          >
        </p>
      </div>
      <div class="col-12 btn-right">
        <button class="btn-sm" @click="showModal = false">Close</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import PageItem from '@/components/items/Page';
export default {
  name: 'NewsPage',
  components: {
    PageItem,
  },
  data() {
    return {
      texts: {
        title: 'Latest inventories submitted',
        submit: 'Submit inventory',
        noAuth: 'to submit an inventory.',
        success: 'Inventory submitted successfully.',
        empty: 'No inventory yet.',
        modalTitle: 'Submit inventory',
      },
      showModal: false,
    };
  },
};
</script>

<style lang="scss">
.bi-info-square-fill {
  cursor: pointer;
  transition: $trs-default;
  &:hover {
    color: $primary;
  }
}
</style>
